<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
<!--					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">-->
<!--						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">-->
<!--							&lt;!&ndash; <a-select-option :value="0">全部</a-select-option> &ndash;&gt;-->
<!--							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--						</a-select>-->
<!--					</a-form-item>-->

<!--          <a-form-item class="ui-form__item" name="organizationId" label="使用影院">-->
<!--            <a-select placeholder="请选择" v-model:value="formState.useCinemaId" style="width: 180px;" @change="getAllCinemaList">-->
<!--              &lt;!&ndash; <a-select-option :value="0">全部</a-select-option> &ndash;&gt;-->
<!--              <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>-->
<!--            </a-select>-->
<!--          </a-form-item>-->
          <a-form-item label="是否一卡通" name="isOneCard" class="ui-form__item">
            <a-select v-model:value="formState.isOneCard" style="width: 180px;" placeholder="请选择状态">
              <a-select-option :value="''">全部</a-select-option>
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item class="ui-form__item" name="cinemaId" label="使用影院">
            <a-select placeholder="请选择" v-model:value="formState.useCinemaId" style="width: 180px;">
							<a-select-option :value="''">全部</a-select-option>
							<a-select-option v-for="item in useCinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="title" label="等级名称">
						<a-input v-model:value="formState.title" placeholder="请输入等级名称"></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择" style="width: 180px;">
							<a-select-option :value="0">启用</a-select-option>
							<a-select-option :value="1">禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['coupon_stored_policy_add']" type="primary" @click="onAdd">添加</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table rowKey="id" :pagination="pagination" :columns="columns" :dataSource="list" :scroll="{ x: 1100 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'isDisabled'">
							{{ record.isDisabled === 1 ? '已禁用' : '已启用' }}
						</template>
            <template v-if="column.key === 'isOneCard'">
              {{ record.isOneCard === 1 ? '是' : '否' }}
            </template>
            <template v-if="column.key === 'cinemaNames'">
              <span>{{ record.isOneCard === 1 ? '全部影院' : record.cinemaNames }}</span>
            </template>
						<template v-else-if="column.key === 'action'">
              <a-button @click="onSee(record)" type="link">查看</a-button>
              <a-button v-permission="['coupon_stored_policy_edit']" @click="onEdit(record)" type="link">修改</a-button>
              <a-button v-permission="['coupon_stored_policy_delete']" @click="onDelete(record)" type="link">删除</a-button>
              <a-button v-permission="['coupon_stored_policy_sync']" @click="onSync(record)" type="link">同步新政策</a-button>
              <a-button v-permission="['coupon_stored_policy_discount']" type="link" @click="onDiscount(record)">卖品折扣配置</a-button>
              <a-button v-permission="['coupon_stored_policy_policy']" type="link" @click="onPolicy(record)">影院政策</a-button>
<!--							<a-dropdown :trigger="['click', 'hover']">-->
<!--								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>-->
<!--								</a-button>-->
<!--								<template #overlay>-->
<!--									<a-menu>-->
<!--										<div @click="onSee(record)">-->
<!--											<a-menu-item>-->
<!--												查看-->
<!--											</a-menu-item>-->
<!--										</div>-->
<!--										<div v-permission="['coupon_stored_policy_edit']" @click="onEdit(record)">-->
<!--											<a-menu-item>-->
<!--												修改-->
<!--											</a-menu-item>-->
<!--										</div>-->
<!--										<div v-permission="['coupon_stored_policy_sync']" @click="onSync(record)">-->
<!--											<a-menu-item>同步新政策</a-menu-item>-->
<!--										</div>-->
<!--										<div v-permission="['coupon_stored_policy_delete']" @click="onDelete(record)">-->
<!--											<a-menu-item>-->
<!--												删除-->
<!--											</a-menu-item>-->
<!--										</div>-->
<!--									</a-menu>-->
<!--								</template>-->
<!--							</a-dropdown>-->
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
<!--  折扣弹窗-->
  <a-modal v-model:visible="showDiscount" title="折扣配置" @cancel="onDiscountCancel" @ok="onDiscountOk">
    <a-row>
      <selectCinema :selectItem="cinemaTableList" @change="(dataList) => { cinemaTableList = dataList; }" v-model:value="discountIds" :discountIds="discountIds" :disCinemaIds="disCinemaIds" showType="discount"></selectCinema>
      <div style="display: flex;align-items: center">
        100%则卖品无优惠折扣
      </div>

    </a-row>
<!--    formState.cinemaId-->
    <div v-if="cinemaTableList.length" style="margin: 10px 0;">
<!--      {{cinemaTableList}}-->
      <a-table rowKey="id" :columns="cinemaColumns" :dataSource="cinemaTableList" :pagination="false">
        <template #bodyCell="{ column,text,record,index}">
          <template v-if="column.key === 'goodsDiscount'">
              <a-input-number v-model:value="record.goodsDiscount" :min="0" :max="100" :precision="2" placeholder="请输入" @change="changeDiscount(record,record.goodsDiscount)"></a-input-number> %

<!--            <div class="editable-cell">-->
<!--              <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">-->
<!--                <a-input v-model:value="editableData[record.key].goodsDiscount" @pressEnter="onSaveDiscount(record.key)" />-->
<!--&lt;!&ndash;                <check-outlined class="editable-cell-icon-check" @click="onSaveDiscount(record.key)" />&ndash;&gt;-->
<!--                <div @click="onSaveDiscount(record.key)">保存</div>-->
<!--              </div>-->
<!--              <div v-else class="editable-cell-text-wrapper">-->
<!--                {{ text || ' ' }}-->
<!--&lt;!&ndash;                <edit-outlined class="editable-cell-icon" @click="onEditDiscount(record.key)" />&ndash;&gt;-->
<!--              <div @click="onEditDiscount(record.key)">编辑</div>-->
<!--              </div>-->

<!--            </div>-->

          </template>
          <template v-if="column.key === 'action'">
            <a-button type="link" @click="onDeleteDiscount(record, index)">删除</a-button>
          </template>
        </template>
      </a-table>
    </div>
    <div v-else style="margin: 10px 0;">
      <!--      {{cinemaTableList}}-->
      <a-table rowKey="id" :columns="cinemaColumns" dataSource="" :pagination="false">
        <template #bodyCell="{ column,text,record,index}">
          <template v-if="column.key === 'goodsDiscount'">
            <a-input-number v-model:value="record.goodsDiscount" :min="0" :max="100" :precision="2" placeholder="请输入" @change="changeDiscount(record,record.goodsDiscount)"></a-input-number> %

            <!--            <div class="editable-cell">-->
            <!--              <div v-if="editableData[record.key]" class="editable-cell-input-wrapper">-->
            <!--                <a-input v-model:value="editableData[record.key].goodsDiscount" @pressEnter="onSaveDiscount(record.key)" />-->
            <!--&lt;!&ndash;                <check-outlined class="editable-cell-icon-check" @click="onSaveDiscount(record.key)" />&ndash;&gt;-->
            <!--                <div @click="onSaveDiscount(record.key)">保存</div>-->
            <!--              </div>-->
            <!--              <div v-else class="editable-cell-text-wrapper">-->
            <!--                {{ text || ' ' }}-->
            <!--&lt;!&ndash;                <edit-outlined class="editable-cell-icon" @click="onEditDiscount(record.key)" />&ndash;&gt;-->
            <!--              <div @click="onEditDiscount(record.key)">编辑</div>-->
            <!--              </div>-->

            <!--            </div>-->

          </template>
          <template v-if="column.key === 'action'">
            <a-button type="link" @click="onDeleteDiscount(record, index)">删除</a-button>
          </template>
        </template>
      </a-table>
    </div>


  </a-modal>
<!-- 政策弹窗-->
  <a-modal v-model:visible="showPolicy" width="1200px" title="使用政策" @cancel="onPolicyCancel" @ok="onPolicyOk(true)">
    <policy v-model:value="policyModel" type="stored" @ok="onPolicyRuleOk" :polCinedmaIds="polCinedmaIds"></policy>
    <a-table :columns="policyColumns" :dataSource="modelRef.cardPolicy" :pagination="false"  style="margin-top: 10px;">

      <template #bodyCell="{column, record, index}">
        <template v-if="column.key === 'type'">
          {{ record.versionType === 3 ? '批量定价' : '场次定价' }}
        </template>
        <template v-if="column.key === 'cinemaNames'">
          {{record.cinemaNames}}
        </template>
        <template v-else-if="column.key === 'isDisabled'">
          <div v-if="record.isDisabled === 0 || record.isDisabled === true">
            启用
          </div>
          <div v-else-if="record.isDisabled === 1 || record.isDisabled === false">
            禁用
          </div>
        </template>

        <template v-if="column.key === 'action'">
          <div style="display: flex;">
							<span>
								<policy :id="record.cinemaId" type="stored" v-model:value="modelRef.cardPolicy[index]" :isEdit="true" :polCinedmaIds="polCinedmaIds">
									<slot><a-button type="primary" ghost>修改</a-button></slot>
								</policy>
							</span>
            <a-button style="margin-left: 20px;" type="danger" ghost @click="onDeletePolicy(record)">删除</a-button>
          </div>
        </template>
      </template>
    </a-table>
  </a-modal>
<!--  &lt;!&ndash;  同步政策弹窗&ndash;&gt;-->
  <a-modal v-model:visible="showSync" title="同步政策" @cancel="onSyncCancel" @ok="onSyncOk">
<!--    <selectCinema :id="modelRef.organizationId" v-model:value="modelRef.cinemaIds" :selectItem="cinemaTableList" @change="(dataList) => { cinemaTableList = dataList; }" type="radio"></selectCinema>-->
    <!--    formState.cinemaId-->
    <div v-if="policyCinema.length" style="margin: 10px 0;">
      <a-table rowKey="id" :dataSource="policyCinema" :pagination="false"
               :columns="syncColumns"
               :rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, onChange: onChange }"
               bordered size="middle">
      </a-table>
    </div>
  </a-modal>
</template>

<script>
	import temp from './temp.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
  import {
    getCardPolicyList,
    deleteCardPolicy,
    syncCardPolicy,
    getCardPolicyDetail, updateCardPolicy, saveCardPolicy, updateGoodsDiscount, policyDetail, updatePolicy
  } from "@/service/modules/coupon.js";
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
  import selectCinema from '@/components/selectCinema/index.vue'
  import policy from "@/components/policy/index.vue";
  export default {
		components: {
      policy,
			Icon,
			temp,
      selectCinema,
    },
		data() {
			return {
				loading: false,
				formState: {
					// organizationId: 0,
          isOneCard:'',
          useCinemaId: '',
					title: '',

				},
        selectedRowKeys: [],
        selectedListData:[],
        selectItem:[],
        type:'checkbox',
        modelRef:{
          type: 3,
          cardType: 3,
          isDisabled: false,
          cinemaList:[],//可用影院列表
          cardPolicy: [],//政策列表
        },
				searchData: {},
				organizationList: [],
        useCinemaAllList: [],
				cinemaAllList: [],
				showModal: false,
				list: [],
				columns: [
        //   {
				// 	title: '影院名称',
				// 	dataIndex: 'cinemaName'
				// },
          {
					title: '等级名称',
					dataIndex: 'title'
				}, {
          title: '使用影院',
          dataIndex: 'cinemaNames',
          key:'cinemaNames'
        }, {
          title: '是否一卡通',
          key: 'isOneCard'
        }, {
					title: '是否启用',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					width: 700,
          fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				isSee: false,
				id: 0,
        isAddPolicy:0,

        showDiscount: false,//折扣弹窗
        showPolicy: false,//政策弹窗
        policyCinema:[],//可用同步政策表格列表
        cinemaTableList:[],//可用影院表格列表
        cinemaColumns:[//可用影院表格表头
          {
            title: '使用影院',
            dataIndex: 'name'
          },
          {
            title: '卖品折扣',
            key:'goodsDiscount',
            width:150,
          },
          {
            title: '操作',
            key: 'action'
          }
        ],
        syncColumns:[//可用影院表格表头
          {
            title: '影院编码',
            key:'code',
            dataIndex: 'code'
          },
          {
            title: '影院名称',
            key:'name',
            dataIndex: 'name'
          }
        ],
        editableData:{},//编辑折扣数据
        policyColumns: [{//政策表头
          title: '政策名称',
          dataIndex: 'pricingName'
        }, {
          title: '可用影院',
          key: 'cinemaName',
          dataIndex: 'cinemaName'
        },  {
          title: '优先级',
          dataIndex: 'pricingLevel'
        }, {
          title: '规则类型',
          key: 'type'
        }, {
          title: '是否启用',
          key: 'isDisabled'
        }, {
          title: '单次限购数',
          dataIndex: 'singleCount'
        }, {
          title: '结算金额(元)',
          dataIndex: 'deductionAmount'
        }, {
          title: '操作',
          key: 'action'
        }],
        organizationId:'',//当前点击操作的organizationId
        cardGradeId:"",//卡等级id
        goodsDiscountList:[],//影院卖品折扣List
        goodsDiscountJson:[],//已选择折扣数组
        discountIds:[],
        showSync:false,//同步政策弹窗
        disCinemaIds:[],
        polCinedmaIds:[],
        newPolicy:[],

        policyModel: {
          versionType: 3,
          isDisabled: true,
          filmType: 1,
          filmList: [],
          hallType: 1,
          hallList: [],
          isHoliday: false,
          visualType: 1,
          dimensionalList: [],
          showList: [],
          startDate: null,
          endDate: null,
          timeList: [],
          deductionAmount: '',
          cinemaList:[],//可用影院列表
          cinemaTableList:[],//可用影院表格列表
          cinemaIds:[],
          cinemaNames:'',


        },

			}
		},
    watch:{
      selectItem: {
        handler(newVal) {
          this.selectedListData = JSON.parse(JSON.stringify(newVal));
        },
        deep: true,
        immediate: true
      },
    },
		created() {
			this.getOrganizationList();
			//this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				// this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
        delete this.searchData.organizationId
        this.searchData.isOneCard = this.searchData.isOneCard !== '' ? this.searchData.isOneCard : undefined;
        this.searchData.isDisabled = this.searchData.isDisabled !== '' ? this.searchData.isDisabled : undefined;
        this.searchData.useCinemaId = this.searchData.useCinemaId !== '' ? this.searchData.useCinemaId : undefined;

        this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCardPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						type: 3,
						...this.searchData
					});
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
          this.newPolicy = ret.data.list;
          this.useCinemaAllList = ret.data.list;
				}
			},
			onSync(item) {
        // console.log(item,item,item)
        // this.modelRef.cinemaIds = []
        this.policyCinema = []
        this.showSync = true
        this.cardGradeId = item.id
        if (item.cinemaIds){
          const idsArray = item.cinemaIds.split(",");
          this.policyCinema = this.hasArray(idsArray,this.newPolicy)
        }
			},
			onAdd() {
				this.isEdit = false;
				this.isSee = false;
				this.id = 0;
				this.showModal = true;
			},
			onEdit(item) {
				this.isEdit = true;
				this.isSee = false;
				this.id = item.id;
				this.showModal = true;
			},
			onSee(item) {
				this.isEdit = true;
				this.isSee = true;
				this.id = item.id;
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定要删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteCardPolicy({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
                // this.getPolicyDetail(item)
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
      //打开折扣弹窗
      onDiscount(item) {
        this.disCinemaIds =[]
        this.discountIds = []
        // this.goodsDiscountJson = []

        this.showDiscount = true
        this.organizationId = item.organizationId
        this.cardGradeId = item.id
        this.getCardPolicyDetail(item)
        // this.discountIds = ['5']
        this.goodsDiscountJson = JSON.parse(item.goodsDiscountJson)
        if (this.goodsDiscountJson != null){
          this.goodsDiscountJson.forEach((item)=>{
            // if (item.cinemaId){
              // // 检查 cinemaId 是否已经存在于 discountIds 中
              // if (!this.discountIds.includes(item.cinemaId)) {
              //   this.discountIds.push(item.cinemaId);
              // }
              if (item.cinemaId && !this.discountIds.includes(item.cinemaId)) {
                this.discountIds.push(item.cinemaId);
              // }
            }
          })
        }else {
          this.goodsDiscountJson = []
        }


      },
      //折扣弹窗ok
      async onDiscountOk() {
        console.log(this.cinemaTableList,"cinemaTableList")
        if(this.cinemaTableList.length != 0) {
          let goodsDiscount = []
          this.cinemaTableList.forEach((item) => {
            goodsDiscount.push({
              cinemaId: item.id,
              cinemaName: item.name,
              goodsDiscount: item.goodsDiscount,
            })
          })
          this.goodsDiscountList = goodsDiscount
          // this.goodsDiscountJson = this.goodsDiscountJson.map(item => {
          //   const match = this.goodsDiscountList.find(listItem => listItem.cinemaId == item.cinemaId);
          //   return match ? match : item;
          // });
            this.goodsDiscountList.forEach(listItem => {
              // 查找 this.goodsDiscountJsont 中是否有相同 id 的对象
              const index = this.goodsDiscountJson.findIndex(jsontItem => {console.log(jsontItem);console.log(listItem)});

              if (index !== -1) {
                // 如果找到相同 id 的对象，替换掉
                this.goodsDiscountJson[index] = listItem;
              } else {
                // 如果没有找到相同 id 的对象，将 listItem 追加到 this.goodsDiscountJsont 数组中
                this.goodsDiscountJson.push(listItem);
              }
            });
        }
        // }else {
        //   const filteredGoodsDiscountJson = this.goodsDiscountJson.filter(item =>
        //     this.discountIds.includes(item.cinemaId)
        //   );
        //   this.goodsDiscountJson = filteredGoodsDiscountJson;
        //   console.log(this.goodsDiscountJson,"删除替换")
        // }

        this.showDiscount = false
          this.loading = true;
          try {
            let ret = await updateGoodsDiscount({
              id: this.cardGradeId,
              goodsDiscountList: this.goodsDiscountJson
            });
            this.loading = false;
            if (ret.code === 200) {
              // this.pagination.total = ret.data.totalCount;
              this.$message.success('操作成功');

            }
          } catch(e) {
            this.loading = false;
          }
        this.onSearch()
      },
      //折扣弹窗cancel
      onDiscountCancel() {
        this.showDiscount = false;
        this.discountIds = [];
        this.goodsDiscountJson = [];
      },
      // 删除选择的可选影院
      onDeleteCinema(item, index) {
        let i = this.modelRef.cinemaIds.indexOf(item.id);
        this.modelRef.cinemaIds.splice(i, 1);
        this.cinemaTableList.splice(index, 1);
      },
      // 删除选择的折扣可选影院
      onDeleteDiscount(item, index) {
        // console.log(this.cinemaTableList,"前cinemaTableListcinemaTableList")
        // console.log(this.discountIds,"前discountIds")
        // console.log(this.goodsDiscountList,"前goodsDiscountList")
        let i = this.discountIds.indexOf(item.id);
        this.discountIds.splice(i, 1);
        this.cinemaTableList.splice(index, 1);

        const id = this.goodsDiscountList.findIndex(discountItem => discountItem.cinemaId == item.id);
        if (id !== -1) {
          // 如果存在相同的cinemaId，则更新该元素
          this.goodsDiscountList.splice(index, 1);
        }
        this.goodsDiscountJson = this.goodsDiscountJson.filter(obj => obj.cinemaId != item.id);
        // console.log(this.cinemaTableList,"后cinemaTableListcinemaTableList")
        // console.log(this.discountIds,"后discountIds")
        // console.log(this.goodsDiscountList,"后goodsDiscountList")
      },
      onDeleteSync(item, index) {
        // let i = this.modelRef.cinemaIds.indexOf(item.id);
        // this.modelRef.cinemaIds.splice(i, 1);
        this.policyCinema.splice(index, 1);

      },
      //打开政策弹窗
      onPolicy(item) {
        this.polCinedmaIds = []
        this.showPolicy = true
        this.cardGradeId = item.id
        this.organizationId = item.organizationId
        this.policyModel.organizationId = this.organizationId
        // console.log(item.cinemaIds,"item.cinemaIdsitem.cinemaIds")
        this.polCinedmaIds = item.cinemaIds.split(',')
        this.getPolicyDetail(item)
      },
      //政策弹窗ok
      onPolicyOk(isBack) {
        this.$refs.form.validateFields().then(async () => {
          let postData = JSON.parse(JSON.stringify(this.modelRef));
          postData.organizationId = this.organizationId ? this.organizationId : '';
          if (postData.cardPolicy.length === 0) {
            return this.$message.warn('请添加线上会员卡使用政策');
          }
          let hasSameLevel = false;
          let levels = [];
          postData.cardPolicy.forEach(item => {
            if (levels.indexOf(item.pricingLevel) !== -1) {
              hasSameLevel = true;
            }
            levels.push(item.pricingLevel);

            if (item.movieList) {
              //  删掉不需要的字段
              delete item.movieList;
            }
            if (item.cinemaTableList) {
              //  删掉不需要的字段
              delete item.cinemaTableList;
            }
            if (item.cinemaHallList) {
              //  删掉不需要的字段
              delete item.cinemaHallList;
            }
            if (item.hallDataList) {
              delete item.hallDataList;
            }
            if (item.versionType === 4 && item.filmFieldIds) {
              item.filmFieldIds = item.filmFieldIds.join(',');
            }
            item.isDisabled = item.isDisabled ? 0 : 1;
            if (item.versionType === 3) {
              item.isHoliday = item.isHoliday ? 1 : 0;
              item.filmList = item.filmList.join(',');
              item.hallList = item.hallList.join(',');
              item.dimensionalList = item.dimensionalList.join(',');
              if (item.startDate) {
                item.startDate = parseInt(this.moment(item.startDate).startOf('day').valueOf() / 1000);
              } else {
                item.startDate = 0;
              }
              if (item.endDate) {
                item.endDate = parseInt(this.moment(item.endDate).endOf('day').valueOf() / 1000);
              } else {
                item.endDate = 0;
              }
              if(item.timeList) {
                item.timeList.forEach(subItem => {
                  subItem.week = subItem.week.join(',');
                  subItem.startTime = this.moment(subItem.startTime).get('hour') * 60 *60 + this.moment(subItem.startTime).get('minute') * 60;
                  subItem.endTime = this.moment(subItem.endTime).get('hour') * 60 *60 + this.moment(subItem.endTime).get('minute') * 60;
                })
              }
              delete item.showList;
            } else {
              delete item.filmFieldIds;
              delete item.isHoliday;
              delete item.movieList;
              delete item.hallDataList;
              delete item.filmType;
              delete item.filmList;
              delete item.hallType;
              delete item.hallList;
              delete item.visualType;
              delete item.dimensionalList;
              delete item.startDate;
              delete item.endDate;
              delete item.timeList;
            }
          });
          if (hasSameLevel) {
            return this.$message.warn('使用政策中含有相同优先级的政策，无法提交');
          }

          try {
            let ret;
            this.loading = true;
            if (this.cardGradeId && !this.isAddPolicy) {
              postData.id = this.cardGradeId;
              ret = await updatePolicy(postData);
            } else {
              postData.id = this.cardGradeId;
              ret = await updatePolicy(postData)
              this.isAddPolicy = 0
            }
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('操作成功');
              if (isBack) {
                this.onBack(true);
              } else {
                this.cinemaAllList = [];
                this.$refs.form.resetFields();
              }
            }
          } catch(e) {
            this.loading = false;
          }
        })

        this.showPolicy = false
      },
      //政策弹窗cancel
      onPolicyCancel() {
        this.showPolicy = false
      },
      //改变折扣
      changeDiscount(item, discount) {
        // 创建新的goodsDiscount对象
        let goodsDiscount = {
          cinemaId: item.id,
          cinemaName: item.name,
          goodsDiscount: discount
        };

        // 查找this.goodsDiscountList中是否有相同cinemaId的元素
        const index = this.goodsDiscountList.findIndex(discountItem => discountItem.cinemaId === item.id);

        if (index !== -1) {
          // 如果存在相同的cinemaId，则更新该元素
          this.goodsDiscountList[index] = goodsDiscount;
        } else {
          // 如果没有相同的cinemaId，则插入新的goodsDiscount对象
          this.goodsDiscountList.push(goodsDiscount);
        }
      },
      //取消政策弹窗
      onSyncCancel() {
        this.selectedRowKeys = [];
        this.selectedListData = [];
        this.selectItem = [];
        this.showSync = false
      },
      //同步政策弹窗
      async onSyncOk() {
        if (this.selectedRowKeys.length == 0){
         this.$message.warn('请选择影院!');
         return;
        }
        this.showSync = true
          this.loading = true;
          try {
            let ret = await syncCardPolicy({
              id: this.cardGradeId,
              cinemaIds:this.selectedRowKeys.join(',')
            })
            this.loading = false;
            if (ret.code === 200) {
              this.$message.success('同步成功!');
            }
          } catch(e) {
            this.loading = false;

          }
        this.selectedRowKeys = [];
        this.selectedListData = [];
        this.selectItem = [];
        this.showSync = false
      },
      async getPolicyDetail(item) {
        console.log(item.cinemaIds)
        this.modelRef
        this.loading = true;
        try {
          let ret = await policyDetail({
            id: item.id
          });
          this.loading = false;
          if (ret.code === 200) {
            if (ret.data && ret.data.length) {

              ret.data.forEach(policyItem => {

                policyItem.isDisabled = policyItem.isDisabled ? false : true;
                policyItem.isHoliday = policyItem.isHoliday ? true : false;
                policyItem.filmList = policyItem.filmList ? policyItem.filmList.split(',') : [];
                policyItem.hallList = policyItem.hallList ? policyItem.hallList.split(',') : [];
                policyItem.showList = policyItem.showList ? JSON.parse(policyItem.showList) : [];
                policyItem.dimensionalList = policyItem.dimensionalList ? policyItem.dimensionalList.split(',').map(item => Number(item)) : [];
                policyItem.startDate = policyItem.startDate ? this.moment(policyItem.startDate *1000) : undefined;
                policyItem.endDate = policyItem.endDate ? this.moment(policyItem.endDate *1000) : undefined;
                policyItem.movieList = policyItem.cinemaFilmList ? policyItem.cinemaFilmList.map(item => {
                  item.id = item.filmId.toString();
                  item.code = item.filmCode;
                  item.shortName = item.film;
                  return item;
                }) : [];
                delete policyItem.cinemaFilmList;

                policyItem.hallDataList = policyItem.cinemaHallList ? policyItem.cinemaHallList.map(item => {
                  item.id = item.hallId.toString();
                  item.name = item.hall;
                  item.code = item.hallCode;
                  return item;
                }) : [];
                delete policyItem.cinemaHallList;

                if (policyItem.timeList) {
                  policyItem.timeList = JSON.parse(policyItem.timeList);
                  policyItem.timeList.forEach(item => {
                    let startTime = this.moment.duration(Number(item.startTime), 'second').hours() + ':' + this.moment.duration(Number(item.startTime), 'second').minutes();
                    let endTime = this.moment.duration(Number(item.endTime), 'second').hours() + ':' + this.moment.duration(Number(item.endTime), 'second').minutes();
                    item.week = item.week.split(',').map(subItem => Number(subItem));
                    item.startTime =  this.moment(startTime, 'HH:mm');
                    item.endTime = this.moment(endTime, 'HH:mm');
                  })
                }
              })
              // ret.data.cardPolicy = ret.data.cardPolicy;
            } else {
              ret.data = [];
            }
            ret.data.isDisabled = ret.data.isDisabled ? false : true;

            // this.getCinemaList(ret.data.organizationId);
            this.modelRef.cardPolicy = ret.data;
            this.modelRef.cardPolicy = this.modelRef.cardPolicy.filter(cinema =>
              this.polCinedmaIds.includes(cinema.cinemaId.toString())
            );

            // this.getCinemaList(ret.data.organizationId)
            // console.log( this.modelRef.cinemaIds," ret.data.cinemaIds ret.data.cinemaIds")


          }
        } catch(e) {
          this.loading = false;
        }
      },

      async getCardPolicyDetail(item) {
        this.loading = true;
        try {
          let ret = await getCardPolicyDetail({
            id: item.id
          });
          this.loading = false;
          if (ret.code === 200) {
            if (ret.data.cardPolicy && ret.data.cardPolicy.length) {
              ret.data.cardPolicy.forEach(policyItem => {
                policyItem.isDisabled = policyItem.isDisabled ? false : true;
                policyItem.isHoliday = policyItem.isHoliday ? true : false;
                policyItem.filmList = policyItem.filmList ? policyItem.filmList.split(',') : [];
                policyItem.hallList = policyItem.hallList ? policyItem.hallList.split(',') : [];
                policyItem.showList = policyItem.showList ? JSON.parse(policyItem.showList) : [];
                policyItem.dimensionalList = policyItem.dimensionalList ? policyItem.dimensionalList.split(',').map(item => Number(item)) : [];
                policyItem.startDate = policyItem.startDate ? this.moment(policyItem.startDate *1000) : undefined;
                policyItem.endDate = policyItem.endDate ? this.moment(policyItem.endDate *1000) : undefined;

                policyItem.movieList = policyItem.cinemaFilmList ? policyItem.cinemaFilmList.map(item => {
                  item.id = item.filmId.toString();
                  item.code = item.filmCode;
                  item.shortName = item.film;
                  return item;
                }) : [];
                delete policyItem.cinemaFilmList;

                policyItem.hallDataList = policyItem.cinemaHallList ? policyItem.cinemaHallList.map(item => {
                  item.id = item.hallId.toString();
                  item.name = item.hall;
                  item.code = item.hallCode;
                  return item;
                }) : [];
                delete policyItem.cinemaHallList;

                if (policyItem.timeList) {
                  policyItem.timeList = JSON.parse(policyItem.timeList);
                  policyItem.timeList.forEach(item => {
                    let startTime = this.moment.duration(Number(item.startTime), 'second').hours() + ':' + this.moment.duration(Number(item.startTime), 'second').minutes();
                    let endTime = this.moment.duration(Number(item.endTime), 'second').hours() + ':' + this.moment.duration(Number(item.endTime), 'second').minutes();
                    item.week = item.week.split(',').map(subItem => Number(subItem));
                    item.startTime =  this.moment(startTime, 'HH:mm');
                    item.endTime = this.moment(endTime, 'HH:mm');
                  })
                }
              })
              // ret.data.cardPolicy = ret.data.cardPolicy;
            } else {
              ret.data.cardPolicy = [];
            }
            ret.data.isDisabled = ret.data.isDisabled ? false : true;
            ret.data.cinemaIds = ret.data.cinemaIds ? ret.data.cinemaIds.split(','): [];
            this.disCinemaIds = ret.data.cinemaIds
            console.log(this.disCinemaIds,"this.disCinemaIds")
            // this.getCinemaList(ret.data.organizationId);
            this.modelRef = ret.data;
            console.log(this.modelRef.cinemaIds,"this.modelRefthis.modelRef")
            this.getCinemaList(ret.data.organizationId)
            // console.log( this.modelRef.cinemaIds," ret.data.cinemaIds ret.data.cinemaIds")


          }
        } catch(e) {
          this.loading = false;
        }
      },
      async getCinemaList(organizationId) {
        this.cinemaAllList = [];
        this.modelRef.cinemaId = undefined;
        let ret = await getCinemaList({
          page: 1,
          pageSize: 999999,
          organizationId: organizationId ? organizationId : undefined
        })
        if (ret.code === 200) {
          this.cinemaAllList = ret.data.list;
          // //排除已选择
          //   //已经使用的 hasArray
            this.cinemaTableList = this.hasArray(this.discountIds,ret.data.list)
          // 遍历 goodsDiscountJson 数组
          if (this.goodsDiscountJson){
            this.goodsDiscountJson.forEach(discountItem => {
              // 查找 cinemaTableList 中是否存在 id 与 cinemaId 匹配的元素
              const index = this.cinemaTableList.findIndex(tableItem => tableItem.id === discountItem.cinemaId);
              if (index !== -1) {
                // 如果找到匹配的 id，将 discountItem 插入到对应位置，并添加属性 dis
                this.cinemaTableList[index] = {
                  ...this.cinemaTableList[index],  // 保留原来的属性
                  goodsDiscount:discountItem.goodsDiscount,
                  goodsDiscountJson: discountItem
                };
              }else {
                this.cinemaTableList[index] ={}
              }
            });
          }
          this.cinemaTableList = this.cinemaTableList.filter(cinema =>
            this.modelRef.cinemaIds.includes(cinema.id.toString())
          );

          //   //可以选择使用
          this.cinemaAllList = this.filterArray(this.discountIds,ret.data.list)
          if (this.goodsDiscountJson){
            this.goodsDiscountJson.forEach(discountItem => {
              const index = this.cinemaAllList.findIndex(tableItem => tableItem.id === discountItem.cinemaId);
              if (index !== -1) {
                this.cinemaAllList[index] = {
                  ...this.cinemaAllList[index],
                  goodsDiscount:discountItem.goodsDiscount,
                  goodsDiscountJson: discountItem
                };
              }else {
                this.cinemaAllList[index] ={}
              }
            });
          }




        }
      },
      filterArray(obj, arr){
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => !values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      },
      hasArray(obj, arr){
        const values = Object.values(obj).map(Number); // 将对象的值转换为数字数组
        return arr.filter(item => values.includes(item.id)); // 过滤数组，返回包含对象值的元素
      },
      onPolicyRuleOk(data) {
        this.isAddPolicy = 1
        this.modelRef.cardPolicy.push(this.$deepClone(data));
        this.policyModel = {
          versionType: 3,
          isDisabled: true,
          filmType: 1,
          filmList: [],
          hallType: 1,
          hallList: [],
          isHoliday: false,
          visualType: 1,
          dimensionalList: [],
          showList: [],
          startDate: null,
          endDate: null,
          timeList: [],
          deductionAmount: '',
          cinemaList:[],//可用影院列表
          cinemaTableList:[],//可用影院表格列表
          cinemaIds:[],
          cinemaNames:''
        };
      },
      onDeletePolicy(index) {
        this.$confirm({
          title: '提示',
          content: '确定移除吗？',
          onOk: () => {
            this.modelRef.cardPolicy.splice(index, 1);
          }
        })
      },
      // onChangeIsOne(value){
      //   if (value == ''){
      //     this.formState.useCinemaId = ''
      //   }
      //
      // },
      onSelectChange(record, selected) {
        console.log(record.id,'record.id')

        if (this.type === 'checkbox') {
          if (selected) {
            this.selectedListData.push(JSON.parse(JSON.stringify(record)));
            this.selectedRowKeys.push(record.id);
            console.log(this.selectedRowKeys,"selectedRowKeys")
            console.log(this.selectedListData,"selectedListData")


          } else {
            this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
            this.selectedListData = this.selectedListData.filter(item => {
              return item.id !== record.id;
            });
            console.log(this.selectedRowKeys,"selectedRowKeys")
            console.log(this.selectedListData,"selectedListData")
          }

        } else {
          if (selected) {
            this.selectedListData = [JSON.parse(JSON.stringify(record))];
            this.selectedRowKeys = [record.id];
          } else {
            this.selectedListData = [JSON.parse(JSON.stringify(record))];
            this.selectedRowKeys = [];
          }
        }
      },
      onSelectAll(selected) {
        if (selected) {
          this.policyCinema.forEach(item => {
            if (this.selectedRowKeys.indexOf(item.id) === -1) {
              this.selectedListData.push(JSON.parse(JSON.stringify(item)));
              this.selectedRowKeys.push(item.id);
            }
          })
        } else {
          this.policyCinema.forEach(item => {
            if (this.selectedRowKeys.indexOf(item.id) !== -1) {
              this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
              this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
            }
          })
        }
      },
      onChange(key) {

      },


		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
  .editable-cell {
    position: relative;
    .editable-cell-input-wrapper,
    .editable-cell-text-wrapper {
      padding-right: 24px;
    }

    .editable-cell-text-wrapper {
      padding: 5px 24px 5px 5px;
    }

    .editable-cell-icon,
    .editable-cell-icon-check {
      position: absolute;
      right: 0;
      width: 20px;
      cursor: pointer;
    }

    .editable-cell-icon {
      margin-top: 4px;
      display: none;
    }

    .editable-cell-icon-check {
      line-height: 28px;
    }

    .editable-cell-icon:hover,
    .editable-cell-icon-check:hover {
      color: #108ee9;
    }

    .editable-add-btn {
      margin-bottom: 8px;
    }
  }
  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }
</style>
